p{
    color: var(--text-gray);

    &:last-child{
        margin-bottom: 0;
    }

    a{
        color: var(--text-black);
    }
}

label{
    color: var(--text-black);
    @extend .text-16, .text-500;
}

.ease{
    @include transition(all 0.35s linear);
}

.opacity{
    &-5{
        opacity: 0.5;
    }
}

// type
h3, h4, h5, h6{
    font-weight: 400;    
}
h1{
    font-size: var(--28);
    font-weight: 700;
}

h2{
    font-size: var(--22);
    font-weight: 600;
}

h3{
    font-size: var(--22);
}

h4{
    font-size: var(--18);
    line-height: 24px;
}

h5{
    font-size: var(--16);
    line-height: 24px;
}

h6{
    font-size: var(--14);
}

.b4{
    @extend .text-13, .text-500;
}

.b3{
    @extend .text-14, .text-600;
}

.b2{
    @extend .text-16, .text-500;
}

.b1{
    @extend .text-18, .text-500;
}

.lh-1{
    line-height: normal;
}

.text{
    &-block{
        color: var(--text-black);

        p{
            color: inherit !important;
        }
    }

    &-black{
        color: var(--text-link);

        &-6{
            color: rgba(#202531, 0.6);
        }
    }

    &-red{
        color: var(--red-text);
    }

    &-gray{
        color: var(--text-gray) !important;

        &-36{
            color: var(--text-gray-36) !important;
        }
    }
    
    &-muted{
        color: rgba(102, 102, 102, 0.80) !important;
    }

    &-white{
        &-07{
            color: rgba(#fff, 0.7);
        }
        &-08{
            color: rgba(#fff, 0.8);
        }
    }

    &-700{
        font-weight: 700;
    }

    &-600{
        font-weight: 600;
    }

    &-500{
        font-weight: 500;
    }

    &-400{
        font-weight: 400;
    }

    &-300{
        font-weight: 300;
    }

    &-200{
        font-weight: 200;
    }

    &-54{
        font-size: var(--54);
        // line-height: 38px;
    }

    &-36{
        font-size: var(--36);
        line-height: 1.4;
        // line-height: 38px;
    }

    &-28{
        font-size: var(--28);
        // line-height: 38px;
    }

    &-22{
        font-size: var(--22);
        // line-height: 28px;
    }

    &-20{
        font-size: var(--20);
        // line-height: 25px;
    }

    &-18{
        font-size: var(--18);
        // line-height: 24px;
    }

    &-16{
        font-size: var(--16);
        // line-height: 24px;
    }

    &-14{
        font-size: var(--14);
        // line-height: 20px;
    }

    &-13{
        font-size: var(--13);
        // line-height: 16px;
    }

    &-12{
        font-size: var(--12);
    }

    &-9{
        font-size: var(--9);
    }
}

.h-56{
    height: 56px;
}

.w-32{
    min-width: 32px;
}

// spacing
.-m-1{
    margin-left: -1px;
    margin-right: -1px;
}
.mb{
    &-20{
        margin-bottom: var(--20);
    }

    &-40{
        margin-bottom: var(--40);
    }
}

.px{
    &-100{
        padding-left: var(--100);
        padding-right: var(--100);
    }
}

.pb{
    &-100{
        padding-bottom: var(--100);
    }
}

textarea.form-control{
    min-height: 120px !important;
}