.button-container {
    display: flex;
    align-items: center;
  }
  
  .button {
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #2980b9;
  }
  
  .value {
    margin: 0 16px;
    font-size: 18px;
    font-weight: bold;
  }
  