@import '~bootstrap/scss/bootstrap';

:root {
    --bg-light: #F8F9FB;
    --bg-light-F9: #F9FAFB;
    --bg-black: #03101D;
    --text-black: #202531;
    --text-muted: #505561;
    --text-gray: #7F8AA5;
    --text-gray-36: #363636;
    --text-link: #03101D;
    --border-card: #D9DBE0;
    --border: #ABB2C4;
    --yellow: #FFA800;
    --border-light: #E5E7EB;
    --muted: #D2D5DC;
    --white: #fff;
    --black: #000;
    --red: #FB4954;
    --red-text: #E94949;
    --green: #00BB6C;
    --red-light: rgba(251, 73, 84, 0.1);
    --green-light: rgba(215, 254, 237, 1);
    --blue: #4965FB;
    --blue-light: rgba(73, 101, 251, 0.07);
    --bg-gray-light: rgba(217, 217, 217, 0.6);



    // type
    --100: 80px;
    --54: 46px;
    --40: 36px;
    --36: 32px;
    --28: 26px;
    --22: 20px;
    --20: 18px;
    --18: 16px;
    --16: 14px;
    --14: 13px;
    --13: 12px;
    --12: 11px;
    --9: 9px;


    @media screen and (max-width: 1199px) {
        --100: 50px;
        --54: 42px;
        --40: 28px;
        --28: 22px;
        --16: 14px;        
    }

    @media screen and (max-width: 767px) {
        --100: 25px;
        --40: 22px;
        --28: 24px;
        --22: 20px;
        --20: 18px;
        --18: 16px;
        --16: 14px;
        --14: 12px;
    }

    @media screen and (max-width: 575px) {
        --100: 15px;
        --36: 24px;
        --28: 22px;
        --22: 18px;
        --20: 18px;
        --18: 15px;
        --16: 14px;
    }
}

@import "./asserts/SCSS/rslider";
@import "./asserts/SCSS/mixin";
@import "./asserts/SCSS/typo";

$space: 30px;


:not(.overflow-auto):-webkit-scrollbar {
    width: 6px;
}
:not(.overflow-auto)::-webkit-scrollbar-thumb {
    background: var(--black);
    border-radius: 12px;
}
:not(.overflow-auto)::-webkit-scrollbar-track {
    border-radius: 12px;
    background-color: rgba(#000, 0.1);
}

body{
    line-height: 1.4;
    font-weight: 400;
    overflow-x: hidden;
    color: var(--text-black);
    font-family: 'General Sans';
    background-color: var(--white);

    @extend .text-16;
}

a{
    text-decoration: none;
}

p img{
    vertical-align: -3px;
}

.active-grad{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(3, 16, 29, 0.10) 100%);
}

.card-grad{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(3, 16, 29, 0.1) 100%);
    border: 1px solid rgba(#03101D, 0.1);
    border-radius: 12px;
}

.border{
    border-color: rgba(#03101D, 0.1) !important;
    border-radius: 12px;

    @media screen and (min-width: 768px) {
        &-md-start{
            border-left: 1px solid rgba(#000, 0.2);
        }
    }

    &-dark{
        border-radius: 15px;
        border: 1px solid var(--border) !important;

        .rounded-16{
            border-radius: inherit;
        }
    }
}

.bg{
    &-gray-light{
        background-color: var(--bg-gray-light) !important;
    }
}



.overflow{
    &-xl-hidden{
        @media screen and (min-width: 1199px) {
            overflow: hidden;
        }
    }
}

.container{
    width: 100%;
    // max-width: 1600px;
    // max-width: 1465px;
    max-width: 1280px;
    padding-left: $space;
    padding-right: $space;

    @media screen and (max-width: 1199px) {
        padding-left: calc($space - 15px);
        padding-right: calc($space - 15px);
    }

    &-fluid{
        padding-left: $space;
        padding-right: $space;

        @media screen and (max-width: 1199px) {
            padding-left: calc($space - 15px);
            padding-right: calc($space - 15px);
        }
    }

    &-sm{
        max-width: 1100px;
    }
}

a, .btn, button{
    @extend .ease;
}

.btn{
    @extend .text-18, .text-500;
    border-radius: 10px;
    padding: 15px;

    @media screen and (max-width: 767px) {
        padding: 10px;
    }

    @media screen and (max-width: 575px) {
        padding: 10px;
    }

    > img{
        border-radius: inherit;
    }

    &-sm{
        padding: 10px;
        font-size: var(--16);
    }

    &-reset{
        &:after{
            display: none;
        }
    }

    &.text-gray.border{
        &:hover{
            background-color: var(--bg-gray-light);
        }
    }

    &-black{
        background-color: var(--bg-black);
        @extend .text-18, .text-500;
        color: var(--white);
        
        
        &:hover{
            background-color: var(--text-gray);
            color: var(--text-link);
        }

        &.show{
            color: var(--white);
            background-color: var(--bg-black);
        }

        &-filter{
            &:after{
                display: none;
            }

            img{
                margin: auto;
            }

            width: 100%;
            height: 100%;
        }
    }

    &-md{
        padding: 10px 25px;
    }

    &-link{
        text-decoration: none;
        color: var(--text-link);
        @extend .b2;

        &:hover{
            color: var(--text-gray);
        }
    }

    &-gray{
        background-color: var(--bg-light-F9);
    }

    &-white{
        background-color: var(--white);
        border: 1px solid var(--border-light);

        &:hover{
            background-color: var(--border-light);
        }

        &-outline{
            border: 1px solid rgba(#fff, 0.1);
            color: var(--white);

            &:hover{
                background-color: var(--white);
                color: var(--text-link);
            }
        }
    }

    &-outline{
        &-white{
            border: 1px solid var(--white);
            color: var(--white);
        }
    }

    &-icon{
        background-position: left 18px center;
        background-repeat: no-repeat;
        background-size: auto;
        @extend .text-16;
    }

    &-filter{
        width: 100%;
        height: 58px;
        display: flex;
        line-height: 1;
        border: 1px solid var(--border) !important;

        &:after{
            display: none;
        }
    }

    &-back{
        margin-left: -40px;
        margin-top: 5px;

        @media screen and (max-width: 1199px) {
            margin-left: 0;
        }
    }

    &-group{
        margin-bottom: -20px;
        z-index: 1;

        @media screen and (max-width: 767px) {
            margin-bottom: -12px;
        }

        .btn{
            line-height: 1; 

            svg{
                margin-top: 2px;
            }

            &-check:checked{
                + .btn{
                    background: 
                    linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(3, 16, 29, 0.2) 100%) !important;
                    border-color: transparent transparent var(--bg-black) transparent !important;
                    border-top: none;
                }
            }
        }
    }

    &-toggles{
        margin: 0 -12px;

        @media screen and (max-width: 767px) {
            margin: 0 -6px;
        }

        .btn{
            width: 33%;
            min-height: 125px;
            border-radius: 15px !important;
            border: 1px solid var(--border);
            @extend .text-14, .text-black, .text-500;

            @media screen and (max-width: 767px){
                min-height: 115px;
            }

            &:hover{
                border-color: var(--bg-black);
            }
        }

        img{
            width: 40px;
            height: 40px;
            margin-bottom: 8px;
        }
    }
}

.input-group{
    border: 1px solid var(--border);
    border-radius: 10px;

    .form{
        &-control{
            border: none;
            line-height: 1.4;
        }
    }
    
    .btn{
        @extend .text-500, .text-16;
        color: var(--muted);

        &:hover{
            color: var(--text-gray);
        }
    }
}

.form{
    &-check{
        &-input{
            margin-top: 3px;
        }

        &-label{
            margin-left: 3px;
        }
    }

    &-switch{
        .form-check-input{
            min-width: 35px;
        }
    }
    &-floating{
        label{
            color: var(--text-gray);
        }
    }

    &-control{
        padding: 12px 15px;
		border-radius: 10px;
        color: var(--text-black);
        font-family: 'General Sans';
        @extend .b2;
		border: 1px solid var(--border);
        background-color: var(--white) !important;

        &.bg{
            &-transparent{
                background-color: transparent !important;
            }

            &-light{
                background-color: rgba(#202531, 0.04) !important;
                border-color: rgba(#202531, 0.04) !important;
            }
        }

        &[disabled]{
            opacity: 0.5 !important;
        }

        &:hover{
			border-color: var(--bg-black);

			&::-webkit-input-placeholder {
			  color: var(--text-gray);
			}
			&:-ms-input-placeholder {
			  color: var(--text-gray);
			}
			&::placeholder {
			  color: var(--text-gray);
			}
		}

		&:focus{
			border-color: var(--text-black);
			box-shadow: none;
		}

		&::-webkit-input-placeholder {
            color: var(--text-gray);
        }
        &:-ms-input-placeholder {
            color: var(--text-gray);
        }
        &::placeholder {
            color: var(--text-gray);
        }

        &-sm{
            padding: 8px;
        }
    }

    &-check{
        &-input{
            --bs-border-color: rgba(0,0,0, 0.33);
            --bs-border-width: 2px;
            height: 19px;
            width: 19px;

            &:checked{
                background-color: #333;
                border-color: #333;
            }
        }
    }

    &-search{
        width: 100%;
        max-width: 420px;
        border-radius: 8px;
        background-color: var(--white);

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }

        .btn{
            color: var(--black);
            padding: 10px;
            border: none;
            
            svg{
                opacity: 0.33;
                display: block;
            }
        }

        .form{
            &-control{
                background-color: var(--white);
                padding: 6px 6px 6px 0px;
                border: none !important;
                color: var(--black);
                font-weight: 500;

                &::-webkit-input-placeholder {
                    color: var(--black);
                }
                &:-ms-input-placeholder {
                    color: var(--black);
                }
                &::placeholder {
                    color: var(--black);
                }
            }
        }
    }
}

.divider{
    color: var(--text-gray);
    @extend .b2;
    margin: 15px 0;

    @media screen and (max-width: 767px){
        margin: 20px 0;
    }
    
    &:before, &:after{
        width: 42%;
        content: "";
        height: 1px;
        opacity: 0.33;
        background-color: currentColor;
    }
}

.icon{
    &-wallet{
        background-image: url('./asserts/IMAGES/wallet-icon.svg');
    }
    
    &-metamask{
        background-image: url('./asserts/IMAGES/metamask-icon.svg');
        
    }
    &-coinbase{
        background-image: url('./asserts/IMAGES/coinbase-icon.svg');
    }
}

.header{
    position: absolute;
    padding: 20px 30px;
    z-index: 2;
    left: 0;
    top: 0;

    img{
        max-width: 100%;
    }
}

.app{
    &-blogin{
        padding-top: 40px;
        padding-bottom: 40px;

        &-banner, &-row{
            min-height: calc(100vh - 80px);
        }

        &-inner{
            // max-width: 530px;
            max-width: 420px;
            margin: auto;
            width: 100%;
        }
    }
}

.navbar{
    &:after{
        bottom: 0;
        height: 1px;
        content: "";
        opacity: 0.1;
        position: absolute;
        left: calc($space - 20px);
        right: calc($space - 20px);
        background-color: var(--black);

        @media screen and (max-width: 1199px) {
            display: none;
        }
    }

    &-toggler-icon{
        --bs-navbar-toggler-icon-bg: url('./asserts/IMAGES/menu.svg');
        background-size: auto;
    }

    &-nav{
        margin-right: 80px;

        @media screen and (max-width: 1440px) {
            margin-right: 10px;
        }

        @media screen and (max-width: 1280px) {
            margin-right: 0;
        }

        a{
            @extend .b2;
            position: relative;
            color: var(--text-gray);
            padding: 24px 18px !important;
            
            @media screen and (max-width: 1440px){
                font-size: 14px;
                padding: 20px 10px !important;
            }

            @media screen and (max-width: 1199px){
                padding: 12px 15px !important;
            }

            + a{
                margin-left: 10px;

                @media screen and (max-width: 1199px){
                    margin-left: 0;
                }
            }

            &.active{
                color: var(--text-link);
                @extend .active-grad;
            }

            .badge{
                top: 3px;
                right: 3px;
                padding: 3px;
                position: absolute;
                @extend .text-12, .text-600;
                background-color: var(--text-gray) !important;

                @media screen and (max-width: 1199px){
                    font-size: 10px;
                }
            }
        }

        &.inner-links{
            border-bottom: 1px solid rgba(#000, 0.1);

            a{
                padding: 16px 14px !important;
                
                @media screen and (max-width: 575px){
                    padding: 14px 12px !important;
                }
            }
        }
    }
}

.col{
    &-filter{
        @media screen and (min-width: 992px) {
            width: 280px;
        }
    }
}

.filter{
    &-sidebar{
        hr{
            opacity: 0.1;
            border-top-color: var(--black);
            margin: 20px 0;
        }
        .form-check{
            margin-bottom: 10px;

            + hr{
                margin-top: 15px;
            }

            &-input{
                margin-top: 2px;
            }

            &-label{
                @extend .b2;

                span{
                    color: var(--text-gray);
                }
            }
        }
    }
}
$yellow: #ff9e12;
$color-6e: #6e6e6e;
$color-e6: #e6e6e6;
$color-5B: #5B86E5;

@mixin groupRow {
    box-shadow: 0px 4px 15px 0px var(--black-trans);
    background: var(--hr);
    border-radius: 16px;
    padding: 20px;

    @include maxDevice(575px){
        padding: 10px;
        font-size: 12px;
    }
}
.time{
    font-weight: normal;
    text-align:right;
    margin: 0;
}
.mint-nft-container{
    display: flex;
    justify-content: center;
    gap: 25rem;
}
.activity{
	&-item{	
		@include transition(all 0.12s ease-in-out);
		border: 1px solid $color-e6;
		border-radius: 16px;
		padding: 24px;

		&:hover{
			background-color: $color-e6;
		}
	}

	&-image{
		position: relative;
		min-width: 68px;
		width: 68px;

		img{
			display: block;
			object-fit: cover;
			border-radius: 50px;
			vertical-align: inherit;
		}
	}

	&-icon{
		background-color: $yellow;
		border-radius: 100%;
		width: 26px;
		color: white;
		height: 26px;
		position: absolute;
		z-index: 15;
		left: -8px;
		top: -8px;
		@include flexbox();

		svg{
			margin: auto;
			width: 12px;
			height: 12px;
		}
	}

	&-content{
		margin-left: 16px;

		h4{
			color: $white;
			line-height: 1;
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 7px;
		}

		p{
			font-weight: 700;
			color: $color-6e;
			margin-bottom: 6px;	

			img{
				width: 16px;
				height: 16px;
				margin-right: 3px;
				border-radius: 15px;
				vertical-align: -3px;
			}

			a{
				color: $color-6e;
				margin-left: 0px;
                font-weight: 500;

                &:hover{
                    color:rgb(50, 50, 246); 
                }
			}
		}
	}
}

.row{
    &-divider{
        position: relative;

        & > div + div{
            border-left: 1px solid rgba(0,0,0,.08);

            @include maxDevice(767px){
                border-left: none;
                margin-top: 20px;
            }
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}

.p-3 {
    padding: 1rem!important;
}
.d-block {
    display: block !important;
}

.card{
    &-dash{
        @include darkCard;
        padding: 24px;
        
        @include maxDevice(575px){
            padding: 12px;
        }

        .tab-content{
            padding: 15px 0 0;
            background: none;

            .dashboard{
                &-tabs{
                    &.nav{
                        @include inline-flex;
                        @include linkHover;
                        border-radius: 25px !important;
                        
                        button{
                            border-radius: 25px !important;
                            color: var(--white);
            
                            &:hover, &.active{
                                background: var(--white) !important;
                                color: var(--bg-card) !important;
                            }
                        }

                        
                    }
                }
            }
        }
    }
    &-avatar{
        background: transparent url('./asserts/IMAGES/avatar-frame.png') no-repeat center / 100% 100%;
        max-width: 250px;
        display: block;
        margin: auto;
        width: 100%;

        &-header{
            min-height: 60px;
            padding: 0 15px;

            @media screen and (max-width: 1199px) {
                min-height: 51px;
            }

            @media screen and (max-width: 575px) {
                padding: 0 8px;
                min-height: 34px;
            }
        }

        &-body{
            height: 267px;
            padding: 10px;
            position: relative;
            // padding-bottom: 104%;

            @media screen and (max-width: 1199px) {
                height: 230px;
            }

            @media screen and (max-width: 575px) {
                height: 160px;
            }
            
            img{
                position: absolute;
                max-height: 92%;
                max-width: 92%;
                margin: auto;
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;
            }
        }

        &-color{
            clip-path: polygon(0 0, 100% 0, 100% 96.3%, 96.1% 100%, 4% 100%, 0 96.2%);
            position: absolute;
            bottom: 4px;
            right: 4px;
            left: 1px;
            top: 1px;

            @media screen and (max-width: 1199px) {
                bottom: 3.1px;
                left: 1.2px;
                right: 3px;
                top: 1.7px;
            }
        }

        &-create{
            background: transparent url('./asserts/IMAGES/avatar-create-frame.png') no-repeat center / 100% 100%;
            color: var(--text-black);
            text-decoration: none;
            max-width: 250px;
            margin: auto;
            height: 100%;
            width: 100%;
        }

        &-preview{
            @extend .card-grad;
            min-height: 440px;
            padding: 10px;
            height: 100%;
            
            img{
                margin: auto auto 0;
                max-height: 90%;
                max-width: 90%;
                display: block;
            }
        }

        &-view{
            @extend .card-grad;
            position: relative;
            height: 200px;
            padding: 8px;

            &-image{
                position: absolute;
                max-height: 75%;
                max-width: 90%;
                display: block;
                margin: auto;
                bottom: 5px;
                right: 0;
                left: 0;
            }
        }

        &-choose{
            position: relative;
            cursor: pointer;
            @extend .border;
            height: 200px;
            padding: 8px;

            div{
                opacity: 0;
                
            }

            &-image{
                position: absolute;
                max-height: 75%;
                max-width: 90%;
                display: block;
                margin: auto;
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;
            }
        }
    }

    &-badges{
        background-image: url('./asserts//IMAGES//badge-frame.png') !important;

        .card-avatar{
            &-body{
                padding: 10px 10px 0;
            }

            &-header{
                padding-bottom: 5%;
            }
        }
    }

    &-container{
        border: 1px solid var(--border-card);
        border-radius: 12px;
        padding: 12px;
    }
}

.dropdown{
    &-menu{
        --bs-dropdown-link-active-bg: #03101D;
    }
    
    &-filter{
        width: 100%;
        overflow: auto;
        min-width: 280px;
        max-height: 300px;
        border-color: var(--border-card);
        box-shadow: 0px 4px 24px 0px rgba(#000, 0.15);
    }

    &-buy{
        min-width: 320px;
    }

    &-noarray{
        &:after{
            display: none;
        }
    }

    &-toggle{
        @extend .d-flex, .align-items-center;

        &::after{
            width: 10px;
            height: 6px;
            margin-left: 8px;
            border: none !important;
            background: transparent url('./asserts/IMAGES/arrow-down.svg') no-repeat center center / cover;
        }
    }

    &-avatar{
        max-height: 92vh;
        min-width: 375px;
        padding: 20px 30px;

        @media screen and (max-width: 767px) {
            height: calc(100vh - 66px);
            top: 58px !important;
            padding: 0 20px 20px;
            min-width: 220px;
            box-shadow: none;
            max-height: none;
            position: fixed;
            border: none; 
            z-index: 99;
            right: 20px;
            left: 20px;
            margin: 0;
        }

        .navbar-nav{
            a{
                color: var(--text-link);
                background: none !important;
                padding: 0px 8px !important;
                font-weight: 600;
                font-size: 16px;

                @media screen and (max-width: 1199px){
                    color: var(--text-black);
                    padding: 0px 8px !important;
                    font-weight: 600;
                    font-size: 16px;
                }

                + a{
                    margin: 10px 0 0 !important;
                }

                &.active{
                    @media screen and (max-width: 1200px) {
                        background: none !important;
                    }
                }
            }
        }
    }
}

.footer{
    background-color: var(--bg-light);
    padding-bottom: 20px;
    padding-top: 35px;
    margin-top: 35px;

    ul{
        li{
            margin-bottom: var(--16);
        }
    }

    a{
        color: var(--text-muted);

        &:hover{
            color: var(--text-black);
        }
    }

    .input-group{
        padding: 4px;
        border-radius: 50px;
        border: 1px solid rgba(#02020E, 0.16);

        input{
            padding: 12px;
            background: transparent !important;
        }

        .btn{
            background-color: var(--bg-black);
            border-radius: 25px !important;
            color: var(--white);
            min-width: 75px;
            padding: 12px;

            svg{
                display: block;
                margin: auto;
            }
        }
    }
}

.social{
    &-icon{
        width: 40px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        &.facebook{
            background-image: url('./asserts//IMAGES/facebook-icon.svg');
        }
        &.twitter{
            background-image: url('./asserts//IMAGES/twitter-icon.svg');
        }
        &.instagram{
            background-image: url('./asserts//IMAGES/instagram-icon.svg');
        }
        &.youtube{
            background-image: url('./asserts//IMAGES/youtube-icon.svg');
        }
        &.discord{
            background-image: url('./asserts//IMAGES/discord-icon.svg');
        }
        &.medium{
            background-image: url('./asserts//IMAGES/medium-icon.svg');
        }
    }
}

.event{
    position: relative;

    &-sm{
        @media screen and (min-width: 575px) {
            .event-poster{
                height: 220px;
                object-fit: cover;
                object-position: center;
            }
        }
        .event-poster{
            clip-path: polygon(5% -6%, 98% 0, 100% 5%, 100% 96%, 98% 100%, 2% 100%, 0% 96%, 0% 5%) !important;
        }
    }

    &-poster{
        border-radius: 0;
        clip-path: polygon(10px 0, 920px -250px, 1800px 10px, 100% 800px, 0 800px, -10px 100%, 0 60px, 0 10px);
    }

    &-info{
        padding: 10px;

        @media screen and (max-width: 767px) {
            padding: 10px 0;
        }

        h2{
            max-width: 80%;
            margin-bottom: var(--14);

            @media screen and (max-width: 767px){
                max-width: 100%;
            }
        }
    }

    &-date{
        left: 8px;
        z-index: 2;
        bottom: 8px;
        min-width: 75px;
        position: absolute;
        padding: 5px 5px 5px;
        background-color: var(--white);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%, 0 85%);

        @media screen and (max-width: 767px) {
            min-width: 55px;
            bottom: 5px;
            left: 5px;
        }

        strong{
            @extend h1;
            margin-bottom: 0;
            font-weight: 700;
        }
    }

    &-btns{
        @media screen and (max-width: 575px) {
            position: absolute;
            z-index: 2;
            right: 8px;    
            top: 8px;
        }

        .btn{
            line-height: 1;

            img{
                max-height: 24px
            }
        }
    }
}

.badge{
    @extend .text-14, .text-600;
    border-radius: 6px;
    padding: 9px 20px;

    &.bg{
        &-danger{
            color: var(--red);
            background-color: var(--red-light) !important; 
        }

        &-success{
            color: var(--green);
            background-color: var(--green-light) !important; 
        }

        &-primary{
            color: var(--blue);
            background-color: var(--blue-light) !important; 
        }
    }
}

.user{
    &-list{
        li{
            position: relative;

            + li{
                margin-left: -8px;
            }

            span{
                clip-path: polygon(12% 0%, 90% 0%, 100% 14%, 93% 84%, 88% 97%, 13% 100%, 4% 80%, 1% 9%);
                background-color: rgba(0,0,0, 0.7);
                color: var(--white);
                position: absolute;
                text-align: center;
                line-height: 40px;
                width: 100%;
                left: 0;
                top: 0;
            }

            img{
                width: 45px;
                height: 40px;
                filter: drop-shadow(-2px 0 var(--white));
            }
        }
    }
}

.upload{
    &-area{
        min-height: 475px;
        cursor: pointer;

        @media screen and (max-width: 991px) {
            min-height: 360px;
        }

        @media screen and (max-width: 991px) {
            min-height: 260px;
        }
    }
}

.rmdp{
    &-container{
        width: calc(100% + 54px);
        position: absolute;
        padding: 9px 14px;
        bottom: 0;
        left: 0;
    }

    &-input{
        @extend .form-control;
        border: none !important;
        padding: 0 !important;
        background-color: transparent !important; 
    }
}

.page{
    &-banner{
        z-index: 1;
        overflow: hidden;
        min-height: 380px;
        position: relative;
        border-radius: 15px;

        .text-center{
            @media screen and (max-width: 767px){
               p{
                   max-width: 100% !important;  
               }
            }
        }

        p{
            margin-bottom: 34px;
            color: inherit;
            opacity: 0.7;

            @media screen and (max-width: 767px){
                margin-bottom: 20px;  
                max-width: 160px;  
            }
        }

        h1{
            font-size: var(--36);
            margin-bottom: 0.45em;
        }

        .btn{
            min-width: 250px;

            @media screen and (max-width: 767px) {
                min-width   : 220px;
            }

            &-small{
                min-width: 200px;

                @media screen and (max-width: 767px) {
                    min-width   : 180px;
                }
            }
        }

        > img{
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            z-index: -1;
            bottom: 0;
            right: 0;

            @media screen and (max-width: 767px) {
                max-height : 50%;
            }
        }

        &-dark{
            background: #000 url('./asserts/IMAGES/market-banner-bg.png') no-repeat center bottom / cover;
        }

        &-card{
            top: 0;
            bottom: 0;
            right: 50px;
            margin: auto;
            width: 250px;
            height: 340px;
            position: absolute;
            padding: 8px 11px 10px 8px;
            background: transparent url('./asserts/IMAGES/banner-card-frame.png') no-repeat center / 100% 100%;
            
            @media screen and (max-width: 767px) {
                right: 15px;
                width: 185px;
                height: 260px;
            }

            @media screen and (max-width: 575px){
                position: relative;
                margin: auto;
                right: 0;
                left: 0;
            }

            &-image{
                position: relative;

                img{
                    width: 240px;
                    height: 260px;

                    @media screen and (max-width: 767px){
                        width: 175px;
                        height: 190px;
                    }
                }

                .countdown{
                    position: absolute;
                    margin: auto;
                    bottom: 10px;
                    right: 0;
                    left: 0;
                }
            }


            &-info{
                padding: 8px 12px 15px;
            }

            &-rewards{
                background-color: var(--text-black) !important;
                min-width: auto !important;
                color: var(--white);
                position: absolute;
                padding: 6px 12px;
                right: 8px;
                top: 8px;
            }
        }

        &-bg{
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            border-radius: initial;
            max-height: 100% !important;
            object-position: center right;
        }

        &-image-sm-expand{
            @media screen and (max-width: 575px) {
                max-height: 80% !important;
                bottom: -20% !important;
                transform: scale(2.1);

                + div{
                    margin-bottom: 110px !important;
                }
            }
        }
    }
}

.swiper{
    overflow: visible !important;
    z-index: 2;

    &-button{
        &-prev, &-next{
            background-color: transparent;
            border: 1px solid var(--text-gray);
            border-radius: 10px 5px;
            height: 30px !important;
            width: 48px !important;
            top: -22px !important;
            z-index: 99;
            background-repeat: no-repeat;
            background-position: center center;

            @media screen and (max-width: 767px) {
                top: -20px !important;
            }

            &:after{
                display: none;
            }

            &:hover{
                border-color: var(--text-muted);
            }
        }
        &-prev{
            left: auto !important;
            right: 60px !important;
            background-image: url('./asserts/IMAGES/slide-arrow.svg');
            @include rotate(180deg);
        }
        
        &-next{
            right: 0px !important;
            background-image: url('./asserts/IMAGES/slide-arrow.svg');
        }
    }

    &-card{
        &-5{
            .swiper-slide{
                width: 231px;

                @media screen and (max-width: 991px) {
                    width: 220px;
                }

                @media screen and (max-width: 767px) {
                    width: 180px;
                }

                @media screen and (max-width: 575px) {
                    width: 160px;
                }
            }
        }
        &-4{
            .swiper-slide{
                width: 329px;

                @media screen and (max-width: 991px) {
                    width: 300px;
                }

                @media screen and (max-width: 767px) {
                    width: 230px;
                }
            }
        }
        &-3{
            .swiper-slide{
                width: 395px;

                @media screen and (max-width: 991px) {
                    width: 360px;
                }

                @media screen and (max-width: 767px) {
                    width: 336px;
                }
            }
        }
    }
}

.card{
    &-view{
        display: block;
        overflow: hidden;
        position: relative;
        border-radius: 15px;
        color: var(--text-black);

        &.text-dark{
            p{
                color: #02020E;
                opacity: 0.8;
            }

            .card-view-icon{
                filter: invert(1);
            }
        }

        &-icon{
            max-width: 40px;
            margin-bottom: 15px;

            @media screen and (max-width: 767px) {
                margin-bottom: 10px;
            }
        }

        &-vertical{
            width: 100%;
            padding-bottom: 138.465%;

            @media screen and (max-width: 767px) {
                padding-bottom: 250px;
            }
            
            &-info{
                position: absolute;
                padding: 25px;
                left: 0;
                top: 0;

                @media screen and (max-width: 991px) {
                    padding: 15px;
                }
            }
        }
        
        &-horizontal{
            width: 100%;
            max-height: 230px;
            min-height: 185px;
            padding-bottom: 58.56%;

            @media screen and (max-width: 767px) {
                padding-bottom: 220px;
            }
            
            &-info{
                margin-top: auto;
                position: absolute;
                margin-bottom: auto;
                padding: 30px;
                width: 55%;
                bottom: 0;
                left: 0;
                top: 0;

                @media screen and (max-width: 991px) {
                    padding: 22px;
                    width: 60%;
                }
            }
        }

        &-information{
            width: 100%;
            padding-bottom: 480px;

            @media screen and (max-width: 767px) {
                padding-bottom: 400px;
            }

            &-info{
                margin-top: auto;
                position: absolute;
                margin-bottom: auto;
                padding: 30px;
                width: 100%;
                z-index: 1;
                bottom: 0;
                left: 0;

                @media screen and (max-width: 991px) {
                    padding: 22px;
                }
            }
        }

        &-sm{
            font-size: var(--14);

            .card-view{
                &-icon{
                    max-width: 30px;
                }

                &-horizontal-info{
                    max-width: 90%;
                    padding: 25px;
                    width: 210px;
                }
            }

            .text-20{
                font-size: var(--16);
            }
        }


        > img{            
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            object-position: center;
        }
    }

    &-shop{
        background: transparent url('./asserts/IMAGES/shop-card-frame.png') no-repeat center / 100% 100%;
        padding: 8px 11px 10px 8px;
        max-width: 340px;
        display: block;
        width: 100%;

        @media screen and (max-width: 575px) {
            .text-20{
                font-size: 14px;
            }
        }

        &-image{
            width: 100%;
            height: 340px;
            object-fit: cover;
            object-position: center;

            @media screen and (max-width: 1280px){
                height: 240px;
            }

            @media screen and (max-width: 1199px){
                height: 190px;
            }

            @media screen and (max-width: 991px){
                height: 240px;
            }

            @media screen and (max-width: 575px){
                height: 160px;
            }
        }

        &-info{
            padding: 15px 5px 0;

            @media screen and (max-width: 575px){
                padding: 8px 0 0;
            }
        }

        &-avatar{
            min-width: 38px;
            width: 38px;
            height: 38px;
            margin-right: 10px;
            border-radius: 10px;

            @media screen and (max-width: 575px){
                max-width: 25px;
                max-height: 27px;
                margin-right: 6px;
            }
        }
    }
}

.rounded{
    &-clip{
        // clip-path: polygon(0 0, 100% 0, 100% 96.3%, 96.1% 100%, 4% 100%, 0 96.2%);
        clip-path: polygon(95% 0, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0 95%, 0 5%, 5% 0);
    }

    &-16{
        border-radius: 16px;
    }
}

.countdown{
    background-color: var(--text-black);
    color: var(--white);
    border-radius: 12px;
    padding: 8px 15px;
    font-weight: 500;
    width: 150px;

    @media screen and (max-width: 575px) {
        width: 120px;
        padding: 8px 10px;
    }

    img{
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
}

.shop-frame{    
    padding-bottom: 100%;
    position: relative;

    img{
        position: absolute;
        max-width: 500px;
        max-height: 90%;
        margin: auto;
        width: 90%;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }
}

.doller{
    position: relative;
    bottom: 5px;
}

.card{
    --bs-border-color-translucent: #D9DBE0;

    &-header{
        @extend h4;
        padding: 15px 20px;
        color: var(--text-gray-36);
        background-color: transparent;

        @media screen and (max-width: 767px) {
            padding: 10px 12px;
        }
    }
    
    &-body{
        padding: 15px 20px;
        
        @media screen and (max-width: 767px) {
            padding: 12px;
        }
    }
    
    .table{
        th, td{
            padding: 20px;

            @media screen and (max-width: 767px) {
                padding: 12px;
            }
        }
    }
}

.table{
    --bs-table-striped-bg: #F7F7F9;

    th{
      @extend .b2, .text-gray, .text-nowrap;  
    }

    td{
        @extend .text-18, .text-500;
    }

    &-stats{
        > div{
            min-width: 140px;
            padding: 0 5px;

            @media screen and (max-width: 1199px){
                min-width: 115px;
            }
        }

        &-count{
            text-align: center !important;
            min-width: 35px !important;
            
            @media screen and (max-width: 1199px){
                min-width: 20px !important;
            }
        }

        &-cell{
            @extend .text-end;  
        }

        &-avatar{
            img:first-child{
                width: 40px;
                height: 40px;
                border-radius: 14px;
            }

            img:last-child{
                position: absolute;
                bottom: -5px;
                right: -5px;
            }
        }
    }
}

.nav{
    &-tabs{
        display: inline-flex;
        white-space: nowrap;
        flex-wrap: nowrap;
        max-width: 100%;
        overflow: auto;

        button{
            border-radius: 0 !important;
            @extend .b2, .text-gray;
            padding: 24px 18px 10px;
            border: none !important;
            margin: 0 !important;

            @media screen and (max-width: 575px) {
                padding: 20px 10px 8px;
            }

            &.active{
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(3, 16, 29, 0.1) 100%) !important;
                color: var(--text-link) !important;
            }
        }
    }
}

.cursor-pointer{
    cursor: pointer;
}
.progress{
    --bs-progress-height: 6px
}

.toggle{
    &-btns{
        border: 1px solid var(--border-card);
        border-radius: 10px;
        padding: 2px;

        .btn{
            padding: 0;
            width: 48px;
            height: 36px;
            line-height: 36px;
            @extend .text-gray, .text-600, .d-flex, .align-items-center, .justify-content-center, .text-center;
            border-radius: 10px !important;
            border: none !important;
            --bs-btn-bg: #fff;
            --bs-btn-border-color: #fff;
            --bs-btn-hover-bg: rgba(3, 16, 29, 0.1);
            --bs-btn-hover-border-color: rgba(3, 16, 29, 0.1);
            --bs-btn-hover-border-color: rgba(3, 16, 29, 0.1);
            --bs-btn-active-bg: rgba(3, 16, 29, 0.1);
            --bs-btn-active-border-color: rgba(3, 16, 29, 0.1);

            @media screen and (max-width: 575px) {
                width: 36px;
                height: 33px;
                border-radius: 7px !important;
            }

            img{
                display: block;
                margin: auto;
                opacity: 0.6;
            }

            + .btn{
                margin-left: 2px;
            }
        }

        &.btn-group .btn-check:checked + .btn{
            background: rgba(3, 16, 29, 0.1) !important;
            color: var(--text-link) !important;

            img{
                opacity: 1;
            }
        }
    }
}

.profile{
    &-stack{
        margin-top: -70px;

        @media screen and (max-width: 575px) {
            margin-top: -32px;
            
            .btn{
                svg{
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &-pic{
        width: 160px;
        border-radius: inherit;

        @media screen and (max-width: 575px) {
            width: 70px;
        }
    }

    &-nav{
        @media screen and (min-width: 992px) {
            margin-top: -52px;
        }
    }
}

.wallet{
    &-ethereum{
        background: #6B8CEF url('./asserts/IMAGES/Ethereum-bg.png') no-repeat center / cover;
    }

    &-polygon{
        background: #03101D url('./asserts/IMAGES/polygon-network-bg.png') no-repeat center / cover;
    }

    &-card{
        min-height: 220px;
        padding: 20px 30px;
        border-radius: 12px;
        
        h4{
            margin-bottom: 0.12em;
            margin-bottom: 20px;
        }

        .row{
            img{
                margin-top: 4px;
            }
        }
        
        @media screen and (max-width: 992px) {
            min-height: 190px;
        }

        @media screen and (max-width: 575px) {
            padding: 15px 20px;
        }
    }
}

.side{
    &-links{
        a{
            @extend .text-20, .text-600, .text-black, .opacity-50;

            &.active{
                @extend .opacity-100;
            }
        }
    }
}

input:checked + .card-avatar-choose{
    @extend .card-grad;

    div{
        opacity: 1;
    }
}

.avatar{
    &-icons{
        .btn{
            padding: 0;
            width: 50px;
            height: 50px;
            @extend .d-flex;

            @media screen and (max-width: 575px){
                width: 40px;
                height: 40px;
            }

            img{
                margin: auto;
                max-width: 30px;
                max-height: 30px;

                @media screen and (max-width: 575px){
                    width: 26px;
                    height: 26px;
                }
            }
        }

        .skin{
            @media screen and (min-width: 768px) {
                order: 0;
            }
            @media screen and (max-width: 767px) {
                order: 4;
            }
            @media screen and (max-width: 575px) {
                order: 0;
            }
            .btn{
                width: 26px;
                height: 26px;
                border-radius: 8px !important;

                @media screen and (max-width: 575px){
                    width: 20px;
                    height: 20px;
                }

                &-color{
                    width: 100%;
                    height: 100%;
                    border-radius: inherit;
                }
            }

            input:checked + .btn{
                padding: 2px !important;
            }
        }

        input{
            + .btn {
                img{
                    opacity: 0.6;
                }
            }
            &:checked + .btn{
                border: none;
                @extend .card-grad;
                box-shadow: 0 1px 0 var(--bg-black);
    
                img{
                    opacity: 1;
                }
            }
        }
    }
}

.form-error{
    top: 0;
    left: 100%;
    margin-left: 60px;
    position: absolute;
    padding: 15px 20px;
    white-space: nowrap;
    background: transparent url('./asserts//IMAGES/error-bg.png') no-repeat top center / 100% 100%;


    &:before{
        top: 30px;
        width: 60px;
        height: 1px;
        left: -60px;
        content: "";
        position: absolute;
        background-color: var(--border);
    }
    
    @media screen and (max-width: 1260px) {
        left: 0;
        padding: 0;
        margin: 8px 0 0;
        background: none;
        position: relative;

        &:before{
            display: none !important;
        }
    }
    
    li{
        font-weight: 500;
        line-height: 24px;
        font-size: var(--13);
    }
}

.toast{
    --bs-body-bg-rgb: 0,0,0;
    color: #fff;

    img{
        filter: invert(1);
    }
}

.content{
    &-area{
        @extend .b1, .text-black;
        
        p{
            color: inherit !important
        }

        h4{
            @extend .text-20, .text-500, .mt-lg-5, .mt-4, .mb-3;
            color: inherit !important;
        }

        li{
            @extend .mb-1;
        }
    }
}

.object-cover{
    object-fit: cover;
    object-position: center;
}