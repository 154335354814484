@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans-Semibold.eot');
    src: url('./GeneralSans-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./GeneralSans-Semibold.woff2') format('woff2'),
        url('./GeneralSans-Semibold.woff') format('woff'),
        url('./GeneralSans-Semibold.ttf') format('truetype'),
        url('./GeneralSans-Semibold.svg#GeneralSans-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans-Bold.eot');
    src: url('./GeneralSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('./GeneralSans-Bold.woff2') format('woff2'),
        url('./GeneralSans-Bold.woff') format('woff'),
        url('./GeneralSans-Bold.ttf') format('truetype'),
        url('./GeneralSans-Bold.svg#GeneralSans-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans-Extralight.eot');
    src: url('./GeneralSans-Extralight.eot?#iefix') format('embedded-opentype'),
        url('./GeneralSans-Extralight.woff2') format('woff2'),
        url('./GeneralSans-Extralight.woff') format('woff'),
        url('./GeneralSans-Extralight.ttf') format('truetype'),
        url('./GeneralSans-Extralight.svg#GeneralSans-Extralight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans-Italic.eot');
    src: url('./GeneralSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('./GeneralSans-Italic.woff2') format('woff2'),
        url('./GeneralSans-Italic.woff') format('woff'),
        url('./GeneralSans-Italic.ttf') format('truetype'),
        url('./GeneralSans-Italic.svg#GeneralSans-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans-Regular.eot');
    src: url('./GeneralSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('./GeneralSans-Regular.woff2') format('woff2'),
        url('./GeneralSans-Regular.woff') format('woff'),
        url('./GeneralSans-Regular.ttf') format('truetype'),
        url('./GeneralSans-Regular.svg#GeneralSans-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans-Medium.eot');
    src: url('./GeneralSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('./GeneralSans-Medium.woff2') format('woff2'),
        url('./GeneralSans-Medium.woff') format('woff'),
        url('./GeneralSans-Medium.ttf') format('truetype'),
        url('./GeneralSans-Medium.svg#GeneralSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans-Light.eot');
    src: url('./GeneralSans-Light.eot?#iefix') format('embedded-opentype'),
        url('./GeneralSans-Light.woff2') format('woff2'),
        url('./GeneralSans-Light.woff') format('woff'),
        url('./GeneralSans-Light.ttf') format('truetype'),
        url('./GeneralSans-Light.svg#GeneralSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

