.tooltip {
    position: absolute;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0.9;
  }

  .bold-on-hover {
    cursor: pointer;
    font-weight: normal;
    transition: font-weight 0.2s ease; /* Smooth transition for the font weight change */
  }
  
  .bold-on-hover:hover {
    font-weight: bold;
  }