// Flexbox display
@mixin flexbox() {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  }
  
  // flex-start | flex-end | center | space-between | space-around 
  @mixin justify-content($justify) {
    -webkit-justify-content: $justify;
       -moz-justify-content: $justify;
        -ms-justify-content: $justify;
            justify-content: $justify;
              -ms-flex-pack: $justify;
  }
  
  // flex-start | flex-end | center | baseline | stretch 
  @mixin align-items($align) {
    -webkit-align-items: $align;
       -moz-align-items: $align;
        -ms-align-items: $align;
         -ms-flex-align: $align;
            align-items: $align;
  }
  
  // row | column | column-reverse | row-reverse
  @mixin flex-direction($direction){
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    -o-flex-direction: $direction;
    flex-direction: $direction;
  }
  
  @mixin flex($fg: 1, $fs: 0, $fb: auto) {
    $fg-boxflex: $fg;
    @if type-of($fg) == 'list' {
      $fg-boxflex: nth($fg, 1);
    }
     -webkit-box: $fg-boxflex;
        -moz-box: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
        -ms-flex: $fg $fs $fb;
            flex: $fg $fs $fb;
  }
  
  // transition
  @mixin transition($transition...) {
      -moz-transition:    $transition;
      -o-transition:      $transition;
      -webkit-transition: $transition;
      transition:         $transition;
  }
  
  // translate
  @mixin translate($x, $y){
      -moz-transform:    translate($x, $y);
      -o-transform:      translate($x, $y);
      -webkit-transform: translate($x, $y);
      transform:         translate($x, $y);
  }
  
  @mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
  }
  
  // rotate
  @mixin rotate($deg...){
      -moz-transform:    rotate($deg);
      -o-transform:      rotate($deg);
      -webkit-transform: rotate($deg);
      transform:         rotate($deg);
  }
  @mixin darkCard {
    box-shadow: 0px 4px 15px 0px var(--black-trans);
    background: var(--bg-card);
    border-radius: 16px;
}
@mixin maxDevice($device){
	@media screen and (max-width: $device){
		@content;
	}
}
@mixin inline-flex {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  @mixin linkHover {
    background: linear-gradient(119.72deg,rgba(189,201,225,.1) -142.08%,rgba(66,102,227,.1) -56.42%,rgba(174,162,241,.1) 33.45%,rgba(165,146,217,.1) 127.53%);
}